import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import $ from 'jquery';

// Components
import SEO from '../components/seo';
import Header from '../components/header';
import Hero from '../components/hero';
import Introduction from '../components/introduction';
import Services from '../components/services';
import Solutions from '../components/solutions';
import References from '../components/references';
import PriceCalculator from '../components/price-calculator';
import FAQ from '../components/faq';
import Contact from '../components/contact';
import Footer from '../components/footer';

// Data
import data from '../data/index.json';
import Video from '../components/video';
import CookieConsent from '../components/cookieConsent';
import { Link } from 'gatsby';

export default function Home() {
	React.useEffect(() => {
		if (window.location.hash.length > 0) {
			window.scroll(0, 0);

			setTimeout(function () {
				window.scroll(0, 0);
			}, 1);

			$('html,body').animate(
				{
					scrollTop: $(window.location.hash).offset().top - 80 + 'px',
				},
				800,
				'swing'
			);
		}
	}, []);

	return (
		<div className="mlg_site-container mlg_page-home">
			<SEO title="Home" />

			<Header />

			<main className="mlg_site-content">
				<Hero {...data.hero} />

				<Introduction {...data.introduction} />

				<Services {...data.services} />

				<Solutions {...data.solutions} />

				<Video {...data.solutions} />

				<section
					id="sicherheit"
					className="mlg_section mlg_bg-gradient-1 mlg_has-seperator-bottom mb-1rem mb-md-95px mb-sm-38px mb-xs-38px">
					<Container className="pt-7rem pb-7rem pt-md-60px pb-md-60px pt-sm-48px pb-sm-48px pt-xs-48px pb-xs-48px">
						<Row>
							<Col lg={6} md={12}>
								<div className="mlg_heading mlg_h-white mb-md-75px mb-sm-24px mb-xs-24px">
									<h2 className="pr-xs-50px" style={{ fontSize: '50px', lineHeight: '65px' }}>
										Unser Schutz für Ihre Daten
									</h2>
									<p
										className="pr-7rem mt-md-40px pr-md-0px pr-sm-0px pr-xs-0px"
										style={{ opacity: 0.8 }}>
										THNG legt größten Wert auf die Sicherheit Ihrer Daten. Darum verbessern wir
										kontinuierlich unsere Sicherheitsvorkehrungen und garantieren den größtmöglichen
										Schutz aller Daten vor, während und nach dem Abstimmungsprozess.
									</p>
								</div>

								<p className="mb-sm-28px mb-xs-28px">
									Durch eine strikte Trennung der sensiblen Daten von den generierten Zugangscodes
									bleibt Ihre Anonymität zu jederzeit gewahrt. Somit haben wir keinen Zugriff auf
									personenbezogenen Daten, da sich auf unseren Servern ausschließlich die Zugangscodes
									befinden. Das verhindert eine Zuordnung der abgegebenen Stimmen zu einzelnen
									Personen. Weiterhin werden von uns keine personenbezogenen Daten auf unserem System
									gespeichert. Der Standort aller eingesetzten Server ist Deutschland. Wir sehen
									ausdrücklich vom Tracking unser Nutzer ab.
								</p>
							</Col>

							<Col lg={6} md={12} className="align-self-end">
								<p>
									Der Datenverkehr von und zu unseren Servern ist nach Stand der Technik durch den
									Einsatz von TLS v1.3 abgesichert. Auch hardwareseitig setzen wir auf zusätzliche
									Sicherheit in Form von Hardware Security Modules (HSM nach PKCS#11).
								</p>
								<p>
									Im Abstimmungsprozess wird sichergestellt, dass jeder Stimmberechtigte nur einmal
									teilnehmen kann, womit eine Mehrfachteilnahme systemseitig ausgeschlossen wird.
									Dabei ist aber nicht einsehbar, ob oder wie eine bestimmte Person bereits abgestimmt
									hat.
								</p>
								<p>
									Die Sicherheit Ihrer Daten und unserer Systeme wurde bereits erfolgreich durch einen
									mehrtägigen Penetrationstest der Firma SySS GmbH überprüft und mit einem
									Goldzertifikat ausgezeichnet. Die SySS GmbH ist ein deutsches Unternehmen mit
									Spezialisierung auf dem Gebiet der Cyber-Sicherheit und Penetrationstests. In
									Zusammenarbeit mit dem Landesbauftragten für Datenschutz und Informationssicherheit
									Baden-Württemberg, wurde ein Verfahren entwickelt, um zu gewährleisten, dass wir
									keinerlei Zugriff auf die Daten der Städte erhahlten.
								</p>
							</Col>
						</Row>
					</Container>
				</section>

				<References {...data.references} />

				<section
					id="service"
					className="mlg_section mlg_service-contract mlg_bg-gradient-2 mlg_has-seperator-bottom">
					<Container>
						<span className="multiple-iphone"></span>
						<img className="multiple-iphone-mobile" src={require('../assets/img/service-img.png')} alt="" />
						<Row>
							<Col lg={5} xs={12} className="align-self-center">
								<div className="mlg_heading mlg_h-white">
									<h2 style={{ fontSize: '50px', lineHeight: '65px' }}>THNG bietet Full-Service</h2>
									<p>
										Neben dem reinen Voting-Tool, dem Herzstück der App, bietet THNG optional
										weitere Leistungen für digitale Wahlen, Abstimmungen und Befragungen an.
									</p>
									<p>
										Das beginnt beim grafischen Design der Benutzeroberfläche, beim Hosting, der
										Beratung beim Formulieren von Fragen und Antwortoptionen durch Experten aus der
										empirischen Sozialforschung und Hinweisen zu rechtlichen Aspekten. Aber auch die
										professionelle Aufbereitung der Ergebnisse sowie die kommunikative Begleitung
										der Votings in der internen und externen Öffentlichkeit sind Bestandteile des
										Angebots.
									</p>

									<h3>Optional bietet THNG folgende Leistungen an:</h3>
								</div>
							</Col>
						</Row>
						<Row>
							<Col
								lg={{ span: 4, order: 1 }}
								sm={{ span: 6, order: 1 }}
								xs={{ span: 12, order: 2 }}
								className="mlg_ul-col">
								<div className="mlg_heading mlg_h-white">
									<ul>
										<li>Beratung und Implementierung eines individuellen, ggf. neuen Designs</li>
										<li>
											Beratung bei der Gestaltung und Formulierung der Fragen und Antwortoptionen
										</li>
										<li>
											Hinweise zu rechtlichen Aspekten bzw. Vermittlung entsprechender
											juristischer Expertise
										</li>
										<li>
											Inhaltliche und grafische Aufbereitung der Voting-Ergebnisse
											(Zusammenfassung in Textform, Formulierung von Kernbotschaften, Umsetzung in
											Tabellen und Infografiken, Gestaltung von Flyern und Broschüren usw.)
										</li>
									</ul>
								</div>
							</Col>
							<Col
								lg={{ span: 4, order: 2 }}
								sm={{ span: 6, order: 2 }}
								xs={{ span: 12, order: 1 }}
								className="mlg_ul-col">
								<div className="mlg_heading mlg_h-white">
									<ul>
										<li>
											Inhaltliche und grafische Aufbereitung von objektiven Informationen, die den
											Nutzern in der App zur Verfügung gestellt werden
										</li>
										<li>
											PR-Beratung und operative Unterstützung bei der Kommunikation des Votings –
											im Vorfeld, begleitend und im Nachgang (Verfassen von Pressemitteilungen und
											Infotexten, Gestaltung von Infografiken, Organisation einer Pressekonferenz,
											Briefing für Medientermine, Vorbereitung von QaAs usw.)
										</li>
										<li>Beratung für eine höhere hardwareseitige Sicherheit</li>
									</ul>
								</div>
							</Col>
						</Row>
						<Row className="mlg_last-row">
							<Col xs={12}>
								<div className="mlg_btn mlg_b-inline mlg_b-white">
									<Link to="#kontakt">Jetzt unverbindlich anfragen</Link>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				{/* <PriceCalculator defaultValue="Kommune" /> */}

				<FAQ {...data.faqs} />

				<Contact />
			</main>

			<Footer />

			<CookieConsent />
		</div>
	);
}
