import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import VideoComponent from './video-component';

function Video(data) {
	return (
		<section id="kurz-erklaert" className="mb-7rem">
			<div className="mlg_t-video-outer-container">
				<Container>
					<Row>
						<Col xs={12}>
							<Row>
								<Col md={12}>
									<div className="mlg_heading mlg_h-small">
										<h2>Kurz erklärt</h2>
									</div>
								</Col>
							</Row>
							<div className="mlg_tabs">
								<VideoComponent data={data} />
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</section>
	);
}

export default Video;
