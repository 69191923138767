import React from 'react';
import { Container, Row, Col, Tab, Nav, Accordion } from 'react-bootstrap';

const images = require.context('../assets/img/', true);
const imagePath = (name) => images(name, true);

export default function FAQ(data) {
	const handleAccorditionClick = React.useCallback((event) => {
		let item;
		if (event.target.classList.contains('mlg_a-header')) {
			item = event.target.parentNode;
		} else {
			item = event.target.parentNode.parentNode;
		}

		if (item.classList.contains('active')) {
			item.parentNode.querySelector('.active').classList.remove('active');
			item.classList.remove('active');
		} else {
			if (item.parentNode.querySelector('.active')) {
				item.parentNode.querySelector('.active').classList.remove('active');
			}
			item.classList.add('active');
		}
	}, []);

	return (
		<section id="faq" className="mlg_section mlg_faqs mb-6rem">
			<Container>
				<Tab.Container defaultActiveKey={data.tabs[0].id}>
					<Row className="mb-1rem">
						<Col lg={7} md={12}>
							<Row>
								<Col lg={12} md={7}>
									<div className="mlg_heading">
										<h2 className="d-none d-lg-block d-xl-block">{data.title.long}</h2>
										<h2 className="mlg_custom-28323 d-block d-lg-none d-xl-none" style={{ fontSize: '50px', lineHeight: '65px' }}>
											{data.title.short}
										</h2>
										<p dangerouslySetInnerHTML={{ __html: data.subtitle }} />
									</div>
								</Col>

								<Col md={5} className="d-lg-none d-xl-none text-right">
									<img
										src={imagePath('./' + data.image.src)}
										alt={data.image.alt}
										className="mlg_img-custom-67312"
									/>
								</Col>
							</Row>
						</Col>

						<Col lg={5} md={12} className="align-self-end text-right text-md-left -mt-md-48px">
							<div className="mlg_tabs">
								<Nav bsPrefix="mlg_t-nav" as="ul">
									{data.tabs.map((element, i) => {
										return (
											<li key={i}>
												<Nav.Link
													bsPrefix="mlg_t-n-item"
													eventKey={element.id}
													className={i === 1 ? 'mlg_t-n-i-color-secondary' : ''}>
													{element.title}
												</Nav.Link>
											</li>
										);
									})}
								</Nav>
							</div>
						</Col>
					</Row>

					<Row>
						<Col lg={5} className="d-xl-block d-lg-block d-md-none d-sm-none d-xs-none">
							<img src={imagePath('./' + data.image.src)} alt={data.image.alt} />
						</Col>

						<Col lg={7} md={12}>
							<Tab.Content className="mlg_t-content">
								{data.tabs.map((element, i) => {
									return (
										<Tab.Pane eventKey={element.id} className="mlg_t-pane" key={i}>
											<Accordion
												defaultActiveKey={element.items[0].id}
												className={
													i === 0 ? 'mlg_accordion' : 'mlg_accordion mlg_a-color-secondary'
												}
												onClick={handleAccorditionClick}>
												{element.items.map((item, j) => {
													return (
														<div
															className={
																'mlg_a-item' +
																(element.items[0].id === item.id ? ' active' : '')
															}
															key={j}>
															<Accordion.Toggle
																as="div"
																className="mlg_a-header"
																eventKey={item.id}>
																<h4>{item.title}</h4>
															</Accordion.Toggle>
															<Accordion.Collapse
																className="mlg_a-content"
																eventKey={item.id}>
																<div>
																	<div
																		dangerouslySetInnerHTML={{
																			__html: item.description,
																		}}></div>
																</div>
															</Accordion.Collapse>
														</div>
													);
												})}
											</Accordion>
										</Tab.Pane>
									);
								})}
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>
			</Container>
		</section>
	);
}
